<template>
  <container class="collectables-list py-16">
    <h1 class="text-3xl font-bold text-center uppercase mb-16">spotlights</h1>
    <div
        class="auction-list-big grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10 mt-5"
      >
        <template
          v-for="spotlight in spotlights"
          :key="spotlight && spotlight.id"
        >
          <div
            v-if="spotlight != null"
            class="artist-card overflow-hidden rounded-lg border shadow-lg py-4 px-4"
          >
            <div class="text-title font-bold text-2.5xl mt-6 text-center">{{ spotlight.name }}</div>
            <div class="h-0.5 my-4 w-full rounded-full bg-gray-200"></div>

            <div class="mt-2 pb-2 border-b text-gray-600">
              <div class="font-semibold text-sm bg-gray-100 inline-block py-0.5 rounded border px-4">Email</div>
              <p class="mt-1">{{ spotlight.email ? spotlight.email : 'Email missing' }}</p>            
            </div>
            <div class="mt-4 pb-2 border-b text-gray-600">
              <div class="font-semibold text-sm bg-gray-100 inline-block py-0.5 rounded border px-4">Phone</div>
              <p class="mt-1">{{ spotlight.phone ? spotlight.phone : 'Phone missing' }}</p>
            </div>
            <div class="mt-4 pb-2 border-b text-gray-600">
              <div class="font-semibold text-sm bg-gray-100 inline-block py-0.5 rounded border px-4">Info</div>
              <p class="mt-1">{{ spotlight.info ? spotlight.info : 'Information missing' }}</p>
            </div>
            <div class="mt-4 pb-2 border-b text-gray-600">
              <div class="font-semibold text-sm bg-gray-100 inline-block py-0.5 rounded border px-4">Work</div>
              <p class="mt-1">{{ spotlight.work ? spotlight.work : 'Work missing' }}</p>
            </div>
            <div class="mt-4 pb-2 border-b text-gray-600">
              <div class="font-semibold text-sm bg-gray-100 inline-block py-0.5 rounded border px-4">Socials</div>
              <p class="mt-1">{{ spotlight.socials ? spotlight.socials : 'Socials missing' }}</p>
            </div>
          </div>
          <div
            v-else
            class="placeholder-card overflow-hidden rounded-3xl bg-gray-100"
            :style="{ 'padding-bottom': '120%' }"
          ></div>
        </template>
      </div>
  </container>
</template>

<script>
import { SpotlightService } from "@/services/apiService";
import { useToast } from "primevue/usetoast";
import Container from "@/components/Container.vue";

export default {
  name: "List",
  components: { Container },
  async setup() {
    const toast = useToast();
    const spotlightsRes = await SpotlightService.list();
    const spotlights = spotlightsRes.data;
    return {
      spotlights
    };
  },
}
</script>

<style scoped>

</style>
